import { Route, Routes, useMatch, useParams } from "react-router-dom";
import Inicio from "../Screens/Inicio/Inicio";
import Evento from "../Screens/Evento/Evento";
import Campus from "../Screens/Campus/Campus";
import Busqueda from "../Screens/Busqueda/Busqueda";
import Error404 from "../Screens/Inicio/404";

function RedirectLatam() {
  const { campusId } = useParams(); // Extrae el parámetro :campusId
  window.location.href = "https://calendario.tec.mx/internacional/latam/"+campusId;
 // return <Navigate to={`/internacional/latam/${campusId}`} replace />; // Redirige con el parámetro
}

export default function AppRouter() {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Inicio />} />
        <Route path="/*" element={<Error404Route />} />
        <Route exact path="/evento/:eventId" element={<Evento />} />
        <Route exact path="/evento/:eventId/gracias" element={<Evento />} />
        {/*<Route exact path="/:nivelParam/:campusId" element={<Campus />} /> */}
        <Route exact path="/prepatec/:campusId" element={<Campus />} />
        <Route exact path="/profesional/:campusId" element={<Campus />} />
        <Route exact path="/busqueda" element={<Busqueda />} />
        <Route exact path="/latam/:campusId" element={<RedirectLatam />} />
      </Routes>
    </>
  );
}

function Error404Route() {
  const match = useMatch("/*");
  return <Error404 path={match?.params["*"]} />;
}
